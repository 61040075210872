import React from 'react';
import {
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
} from '@material-ui/core';
import { DialogCustomTitle } from './DialogCustomTitle';
import { ideaCreateActions, ideaCreateDialogsActions, multiAreaCascadeActions } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Upload, UploadButton } from '../Upload';
import InfoIcon from '@material-ui/icons/Info';
import { dialogCss } from './dialogCss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import dialogVideoSvg from '../../assets/imgs/dialog-video.svg';
import { returnPage } from '../../helpers';
import VideocamIcon from '@material-ui/icons/Videocam';
import { DialogCreateIdea } from './DialogCreateIdea';
import config from '../../config';
import { useWindowSize } from '../../hooks';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    textTransform: 'lowercase',
  },
  alert: {
    color: theme.palette.grey[500],
    paddingBottom: 24,
  },
  strong: {
    fontWeight: 'bold',
  },
  dividerBottom: {
    borderBottom: 'none',
  },
}));

function DialogVideo() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dialogClasses = dialogCss();
  const { dialogVideo, fromHistory } = useSelector((state) => state.ideaCreateDialogs);
  const { video } = useSelector((state) => state.ideaCreate);
  const [width] = useWindowSize();
  const { t } = useTranslation();

  function next() {
    if (fromHistory[fromHistory.length - 1] === 'DIALOG_SEND_IDEA') {
      dispatch(ideaCreateDialogsActions.openDialogSendIdea());
      return;
    }

    dispatch(ideaCreateDialogsActions.openDialogTitleDescription());
  }

  function previous() {
    dispatch(ideaCreateDialogsActions.previousDialog())
  }
  function close() {
    if (width < config.RESPONSIVE_MOBILE) {
      dispatch(ideaCreateDialogsActions.openDialogClose());
    } else {
      dispatch(ideaCreateActions.clearAll());
      dispatch(ideaCreateDialogsActions.close());
    }
    dispatch(multiAreaCascadeActions.clearAll());
  }
  
  return (
    <DialogCreateIdea open={dialogVideo}>
      <DialogCustomTitle id="customized-dialog-title" onClose={close}>
        {t('publicar_video')}
      </DialogCustomTitle>
      <DialogContent dividers className={classes.dividerBottom}>
        <Upload
          type="video"
          img={dialogVideoSvg}
          name="video"
          message={t('insira_um_video_apresentando_a_sua_ideia')}
          accept="video/*"
          maxSize={52428800}
        />
      </DialogContent>
      <DialogActions className={dialogClasses.containerAction}>
        <div style={{ width: '100%' }}>
          <div className={classes.alert}>
            <InfoIcon fontSize="small" style={{ marginRight: 6 }} />{' '}
            {t('o_arquivo_deve_possuir_tamanho_maximo_de')}{' '}
            <span className={classes.strong}>50mb</span>.
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {fromHistory.length > 0 && (
                <Button
                  color="primary"
                  onClick={previous}
                  startIcon={<ArrowBackIcon />}
                  className={dialogClasses.button}
                >
                  {t('voltar')}
                </Button>
              )}
            </div>
            <div>
              {video && !video.id && (
                <UploadButton
                  type="video"
                  text={t('adicionar_video')}
                  icon={<VideocamIcon />}
                  className={dialogClasses.button}
                />
              )}

              <Button
                className={dialogClasses.button}
                variant="contained"
                color="primary"
                startIcon={<ArrowForwardIcon />}
                onClick={() => next()}
              >
                {t('avancar')}
              </Button>
            </div>
          </div>
        </div>
      </DialogActions>
    </DialogCreateIdea>
  );
}

export { DialogVideo };
