import React, { useState } from 'react';
import { Card, makeStyles, CardContent, Button, CircularProgress, useTheme, Box } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import DoneIcon from '@material-ui/icons/Done';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { LinearProgressWithLabel } from '../Shared';
import { useDispatch } from 'react-redux';
import { challengeActions } from '../../actions';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../Shared';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1),
    color: theme.palette.neutrals.low.medium,
    fontSize: 13,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  icon: {
    // backgroundColor: '#7E0386',
    width: 40,
    height: 40,
    borderRadius: '50%',
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
  },
  line: {
    borderLeft: '3px solid',
    height: 16,
    position: 'absolute',
    left: 27,
    top: -8,
  },
  containerButton: {
    marginTop: 30,
  },
  circleProgress: {color: theme.palette.primary.main, marginRight: 10}
}));

function ChallengeProgressCard(props) {
  const classes = useStyles();
  const {
    challenge,
    imagem,
    criteria,
    dates,
    award,
    progress,
  } = props.progress;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  function handleLoadingButton() {
    setLoading(!loading);
  }

  function color(color) {
    switch (color) {
      case 'active':
        return theme.palette.primary.main;

      case 'concluded':
        return theme.palette.neutrals.low.medium;

      case 'disabled':
        return theme.palette.neutrals.low.light;

      default:
        return 'grey';
    }
  }

  function colorIcon(color) {
    switch (color) {
      case 'active':
        return '#7E0386';

      case 'concluded':
        return '#00C766';

      case 'disabled':
        return '#D5D5D5';

      default:
        return 'grey';
    }
  }

  function sendChallenge() {
    dispatch(challengeActions.createChallenge(props.challenge,t('seu_desafio_foi_criado')));
  }

  return (
    <Card>
      <CardContent>
        <Box className={classes.header}>{t('progresso_do_casdastro')}</Box>
        <Box>
          {/* challange */}
          <Box style={{ display: 'flex', padding: 8 }}>
            <Box
              className={classes.icon}
              style={{ backgroundColor: colorIcon(challenge) }}
            >
              {challenge === 'active' && (
                <DescriptionIcon style={{ color: 'white' }} />
              )}
              {challenge === 'concluded' && (
                <DoneIcon style={{ color: 'white' }} />
              )}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box className={classes.text} style={{ color: color(challenge) }}>
                {t('desafio')}
              </Box>
            </Box>
          </Box>
          {/* imagem */}
          <Box style={{ display: 'flex', padding: 8, position: 'relative' }}>
            <Box
              className={classes.line}
              style={{ color: colorIcon(imagem) }}
            ></Box>
            <Box
              className={classes.icon}
              style={{ backgroundColor: colorIcon(imagem) }}
            >
              {imagem === 'active' && (
                <AddAPhotoIcon style={{ color: 'white' }} />
              )}
              {imagem === 'concluded' && (
                <DoneIcon style={{ color: 'white' }} />
              )}
              {imagem === 'disabled' && (
                <AddAPhotoIcon style={{ color: 'white' }} />
              )}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box className={classes.text} style={{ color: color(imagem) }}>
                {t('imagem')}
              </Box>
            </Box>
          </Box>

          {/* criteria */}
          <Box style={{ display: 'flex', padding: 8, position: 'relative' }}>
            <Box
              className={classes.line}
              style={{ color: colorIcon(criteria) }}
            ></Box>
            <Box
              className={classes.icon}
              style={{ backgroundColor: colorIcon(criteria) }}
            >
              {criteria === 'active' && (
                <AssignmentTurnedInIcon style={{ color: 'white' }} />
              )}
              {criteria === 'concluded' && (
                <DoneIcon style={{ color: 'white' }} />
              )}
              {criteria === 'disabled' && (
                <AssignmentTurnedInIcon style={{ color: 'white' }} />
              )}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box className={classes.text} style={{ color: color(criteria) }}>
                {t('criterios')}
              </Box>
            </Box>
          </Box>

          {/* data */}
          <Box style={{ display: 'flex', padding: 8, position: 'relative' }}>
            <Box
              className={classes.line}
              style={{ color: colorIcon(dates) }}
            ></Box>
            <Box
              className={classes.icon}
              style={{ backgroundColor: colorIcon(dates) }}
            >
              {dates === 'active' && (
                <AssignmentIcon style={{ color: 'white' }} />
              )}
              {dates === 'concluded' && <DoneIcon style={{ color: 'white' }} />}
              {dates === 'disabled' && (
                <AssignmentIcon style={{ color: 'white' }} />
              )}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box className={classes.text} style={{ color: color(dates) }}>
                {t('datas')}
              </Box>
            </Box>
          </Box>

          {/* awards */}
          <Box style={{ display: 'flex', padding: 8, position: 'relative' }}>
            <Box
              className={classes.line}
              style={{ color: colorIcon(award) }}
            ></Box>
            <Box
              className={classes.icon}
              style={{ backgroundColor: colorIcon(award) }}
            >
              {award === 'active' && (
                <EmojiEventsIcon style={{ color: 'white' }} />
              )}
              {award === 'concluded' && <DoneIcon style={{ color: 'white' }} />}
              {award === 'disabled' && (
                <EmojiEventsIcon style={{ color: 'white' }} />
              )}
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center' }}>
              <Box className={classes.text} style={{ color: color(award) }}>
                {t('premiacao')}
              </Box>
            </Box>
          </Box>

          {/* progress bar */}
          <LinearProgressWithLabel value={progress}></LinearProgressWithLabel>

          {/* button publicar */}
          <Box className={classes.containerButton}>
            {loading ? 
              <LoadingButton />
            : 
              <Button
                style={{ width: '100% '}}
                variant="contained"
                color="primary"
                onClick={() => { handleLoadingButton(); sendChallenge(); }}
                disabled={progress !== 100}
              >
                {t('publicar_desafio')}
              </Button>
            }
            
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

export { ChallengeProgressCard };
