import React, { useEffect} from 'react';
import { DialogTitleDescription } from './DialogTitleDescription';
import { DialogArea } from './DialogArea';
import { DialogChallenge } from './DialogChallenge';
import { DialogSendIdea } from './DialogSendIdea';
import { DialogPhoto } from './DialogPhoto';
import { DialogVideo } from './DialogVideo';
import { DialogFile } from './DialogFile';
import { DialogLink } from './DialogLink';
import { DialogCanvas } from './DialogCanvas';
import { DialogValueCanvas } from './DialogValueCanvas';
import { DialogClose } from './DialogClose';
import { DialogMultiArea } from './DialogMultiArea';
import { DialogClassification } from './DialogClassification';
import { useDispatch, useSelector } from "react-redux";
import { 
  ideaCreateActions, 
  ideaCreateDialogsActions, 
  multiAreaCascadeActions
} from "./../../actions"
import { useWindowSize } from '../../hooks';
import config from '../../config';

function DialogAllCreateIdea({ challenge }) {
  const dispatch = useDispatch();
  const { imagineLocals } = useSelector(state => state.domains)
  const { personalizedClassification } = useSelector((state) => state.personalizedClassification);

  const [width] = useWindowSize();

  useEffect(() => {
    if (imagineLocals) {
      dispatch(ideaCreateActions.setLocals(imagineLocals));
    }
  }, [dispatch, imagineLocals]);

  return (
    <div>
      <DialogTitleDescription />
      <DialogMultiArea challenge={challenge}/>
      <DialogArea challenge={challenge}/>
      {config.PERSONALIZED_CLASSIFICATION_COLABORATOR && !!personalizedClassification.length && <DialogClassification />}
      <DialogChallenge />
      <DialogSendIdea />
      <DialogPhoto />
      <DialogVideo />
      <DialogFile />
      <DialogLink />
      <DialogCanvas />
      <DialogValueCanvas />
      <DialogClose />
    </div>
  );
}

export { DialogAllCreateIdea };
