import React from 'react';
import MaskedInput from 'react-text-mask';
function PhoneMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        ')',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}



function CPFMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function CPFMaskCustomEs(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/
      ]}
      placeholderChar={'\u2000'}
      guide={false}
      showMask
    />
  );
}

function formatReal(value) {
  var tmp = value+'';
  tmp = tmp.replace(/([0-9]{2})$/g, ".$1");


   if( tmp.length > 2 ) {  
    tmp = parseFloat(tmp).toLocaleString(
      'pt-BR', 
      {minimumFractionDigits: 2, maximumFractionDigits: 2}
    );
   }
 

   return tmp;
}

function formatBRLFromFloat(value) {
  const valueBRL = parseFloat(value).toLocaleString('pt-BR');
  const valueBRLParts = valueBRL.split(",");

  if (valueBRLParts.length > 1) {
    if (Number(valueBRLParts[1]) < 10) {
      return valueBRL + "0";
    } else {
      return valueBRL
    }
  } else {
    return valueBRL + ",00";
  }
}

function formatRealToNumber(value) {
  var formatedValue = value.toString().replaceAll(".", "")
  formatedValue = formatedValue.toString().replace(",",".");
  var valor = parseFloat(formatedValue).toFixed(2);
  return valor;
}

function formatToDolar(value) {
  return value.toString().replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

function formatRealOld(value) {
  var tmp = value+'';
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if( tmp.length > 6 ) {
    tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
  }
  return tmp;
}
export { CPFMaskCustom, CPFMaskCustomEs, PhoneMaskCustom, formatReal, formatRealToNumber, formatToDolar, formatBRLFromFloat };
