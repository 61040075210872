import React from 'react';
import { Typography } from '@material-ui/core';
import { CustomTooltip } from '../Shared/CustomTooltip';
import filesize from 'filesize';
import FileIcon from '@material-ui/icons/DescriptionOutlined';
import { useStyles } from './attachments';

function CardAttachment(props) {

    const classes = useStyles()

    function getFileName(rawName, rawExt){

        if(rawName[rawName.length - 1] === '.'){
            return rawName.slice(0, -1).concat(rawExt)
        } 

        return rawName.concat(rawExt)
    }

    function getFileSize(rawSize) {
        const size = parseFloat(rawSize);
        return filesize(size)
    }

    const fullName = getFileName(props.fileName, props.fileExtension)

    return(
        <a
        className={classes.card}
        href={props.fileURL}
        target="_blank"
        rel="noopener noreferrer">
            <FileIcon className={classes.icon}/>
            <div className={classes.content}>
                <CustomTooltip title={fullName} aria-label={fullName}>
                    <Typography
                        className={classes.title}
                    >
                    {fullName}
                    </Typography>
                </CustomTooltip>
                <Typography
                    className={classes.subitle}
                >
                {getFileSize(props.fileSize)}
                </Typography>
            </div>
        </a>
    );
}

export { CardAttachment }